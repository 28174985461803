import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Container } from "react-bootstrap";
import PostPaginations from "../post-paginations";

import causeImage1 from "../../assets/images/causes/cause-1-1.jpg";
import causeImage2 from "../../assets/images/causes/cause-1-2.jpg";
import causeImage3 from "../../assets/images/causes/cause-1-3.jpg";
import causeImage4 from "../../assets/images/causes/cause-1-4.jpg";
import causeImage5 from "../../assets/images/causes/cause-1-5.jpg";
import causeImage6 from "../../assets/images/causes/cause-1-6.jpg";
const CAUSES_DATA = [
  {
    image: causeImage1,
    title: "MECHANIKA",
    text: "Naprawiamy różne auta...",
	  id: "uslugi",

  },
  {
    image: causeImage2,
    title: "DIAGNOSTYKA",
    text: "Oferujemt usługi diagnostyczne...",

  },
  {
image: causeImage3,
    title: "OPONY / PRZECHOWYWALNIA",
    text: "Wymiana i przechowywanie opon...",
  },
  {
image: causeImage4,
    title: "KLIMATYZACJA",
    text: "Pełna obsługa w zakresie klimatyzacji...",
  },
  {
image: causeImage5,
    title: "GEOMETRIA",
    text: "Wyważanie, zbieżność...",
  },
  {
image: causeImage6,
    title: "SERWIS OLEJOWY",
    text: "Wymiana oleju...",
  }
];



const CausesPage = () => {
  return (
    <section className="causes-page pt-120 pb-120">
      <Container>
        <div className="causes-col__3">
          {CAUSES_DATA.map(
            (
              { image, progressCount, raised, goal, title, text, link },
              index
            ) => (
              <div className="cause-card" key={`cause-card-key-${index}`}>
                <div className="cause-card__inner">
                  <div className="cause-card__image">
                    <img src={image} alt="" />
                  </div>
                  <div className="cause-card__content">
                    
                    <h3 id="mechanika">
                      {title}
                    </h3>
                    <p>{text}</p>
                    
                  </div>
                </div>
              </div>
            )
          )}
        </div>
       
      </Container>
    </section>
  );
};

export default CausesPage;
